@import "./style/fonts.scss";

body {
  margin: 0;
  font-family: "KIATextLatinbasic", "sans-serif";
  background-color: #101010;
}

section {
  height: 100vh;

  @media (max-width: 534px) {
    height: auto;
  }
}

.container {
  display: flex;
  align-items: center;
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media (max-width: 534px) {
    display: block;
    padding: 20px 10px 55px;
  }
}
