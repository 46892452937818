.choose-models-card {
  padding: 0 0 0 50px;

  &__border-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 183px;
    height: 184px;
    padding: 24px 10px;
    border-radius: 10px;
    background-color: #333C45;
    cursor: pointer;
    text-decoration: none;
    color: black;
  }

  &__wrapper {
    position: relative;
    height: 100%;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
  }

  &__image {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__link {
    position: absolute;
    bottom: 12px;
    right: 11px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #002C5E;
  }
}