.choose-models-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 590px;
  width: 100%;
  margin: 0 auto;

  &__logo {
    width: 93px;
    height: 22px;
    margin-bottom: 75px;

    @media (max-width: 534px) {
      order: 1;
      margin-bottom: 18px;
    }
  }

  &__cards {
    position: relative;
    right: 26px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -36px;

    @media (max-width: 534px) {
      position: static;
      order: 3;
    }
  }

  &__card {
    flex-shrink: 0;
    width: calc(50% - 72px);
    max-width: 183px;
    margin: 0 36px 32px;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);

    @media (max-width: 534px) {
      order: 2;
      margin-bottom: 18px;
    }
  }
}